// Libraries
// import { combineReducers } from "states";

// Types
// import * as types from "./userTypes";

export const EventsReducers = (events = [], action) => {
  switch (action.type) {
    default:
      return events;
  }
};
