// Libraries
import React from 'react';

const Footer = () => {
	return (
    <footer>
      <nav>
        <ul>
          <a href='#'><li>About</li></a>
          <a href='#'><li>Contact</li></a>
        </ul>
      </nav>
    </footer>
  );
};

// class Footer extends React.Component {
//   render(
//     return (
//     <div>
      
//     </div>
//     );
//   );
// };

export default Footer;