// Libraries
// import { combineReducers } from "states";

// Types
// import * as types from "./userTypes";

const initialUser = {
  token: null
};
export const userReducers = (user = initialUser, action) => {
  switch (action.type) {
    default:
      return user;
  }
};
